<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Campanha de Estoque</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="6"
                    lg="3"
                    md="6"
                    sm="6"
                >
                    <v-switch 
                        v-model="campanhaEstoqueRequest.status"
                        label="Ativar"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Nome da Campanha</label>
                    <v-text-field
                        v-model="campanhaEstoqueRequest.descricao" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Estados Participantes</label>
                    <v-combobox
                        v-model="campanhaEstoqueRequest.estadosSelected"
                        :items="listEstados"
                        item-text="codigo"
                        item-value="codigo"
                        multiple
                        small-chips
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Condição de Pagamento</label>
                    <v-combobox
                        v-model="campanhaEstoqueRequest.condicaoPagamentoSelected"
                        :items="listCondicaoPagamento"
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="3"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Aplicar desconto a partir de qual valor?</label>
                    <v-text-field
                        v-model="campanhaEstoqueRequest.valor" 
                        prefix="R$"
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="3"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Desconto</label>
                    <v-text-field
                        v-model="campanhaEstoqueRequest.desconto" 
                        suffix=" %"
                        v-formatMoney="percFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="false">
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Lista de Distribuidores que fazem parte da campanha'" />
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="campanhaEstoqueRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionButtons,
            ActionDialog: ActionDialog
        },

        data: () => ({

            validForm: true,

            campanhaEstoqueRequest: {
                id: 0,
                status: 1,
                importado: 0,
                nome: "",
                estadosSelected: null,
                condicaoPagamentoSelected: null,
                listItemsCampanhaEstoque: []
            },

            moneyFormat: {
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },

            listEstados: [],
            listCondicaoPagamento: [],
            validarDistribuidorParticipacaoCampanha: true,
            loadingDistribuidor: false,

            loading: false,
            
            validations: {
                required: required,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            // async deleteItem(index) {
            //     this.campanhaEstoqueRequest.listItemsCampanhaEstoque.splice(index, 1);
                
            //     let lastIndex = this.campanhaEstoqueRequest.listItemsCampanhaEstoque.length - 1;

            //     this.campanhaEstoqueRequest.listItemsCampanhaEstoque[lastIndex].showAddNewItem = true;
            // },

            // async addNewItem(itemAtual) {

            //     if (itemAtual !== null && itemAtual !== undefined) {
            //         itemAtual.showAddNewItem = false;
            //     }

            //     this.campanhaEstoqueRequest.listItemsCampanhaEstoque.push({
            //         distribuidorSelected: null,
            //         showAddNewItem: true
            //     })
            // },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("campanhaEstoqueModule/GetById", this.id);

                    if (response.success) {
                        this.campanhaEstoqueRequest = response.result;
                        
                        this.campanhaEstoqueRequest.valor = this.campanhaEstoqueRequest.valorFormatted;
                        this.campanhaEstoqueRequest.desconto = this.campanhaEstoqueRequest.descontoFormatted;

                        if (this.campanhaEstoqueRequest.estados != null && this.campanhaEstoqueRequest.estados != undefined && this.campanhaEstoqueRequest.estados != "") {
                            
                            this.campanhaEstoqueRequest.estadosSelected = [];
                            
                            this.campanhaEstoqueRequest.estados.toString().split(";").forEach(itemEstado => {

                                if (itemEstado != null && itemEstado != undefined && itemEstado != "") {
                                    this.campanhaEstoqueRequest.estadosSelected.push({
                                        codigo: itemEstado
                                    })
                                }
                            });
                        }
                    }
                }
                else {
                    this.campanhaEstoqueRequest.status = 1;
                    this.campanhaEstoqueRequest.importado = 0;
                }
            },

            async getLists() {
                
                this.listEstados = await this.$store.dispatch("estadoModule/List");
                this.listCondicaoPagamento = await this.$store.dispatch("condicaoPagamentoModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/campanhas/campanhaEstoqueList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    if (this.campanhaEstoqueRequest.status === null) {
                        this.campanhaEstoqueRequest.status = 0;
                    }

                    if (this.campanhaEstoqueRequest.importado === null) {
                        this.campanhaEstoqueRequest.importado = 0;
                    }

                    if (this.campanhaEstoqueRequest.condicaoPagamentoSelected != null && this.campanhaEstoqueRequest.condicaoPagamentoSelected != undefined) {
                        this.campanhaEstoqueRequest.idCondicaoPagamento = this.campanhaEstoqueRequest.condicaoPagamentoSelected.id;
                    }

                    let estados = ""
                    if (this.campanhaEstoqueRequest.estadosSelected != null && this.campanhaEstoqueRequest.estadosSelected != undefined) {

                        this.campanhaEstoqueRequest.estadosSelected.forEach(itemEstado => {
                            estados += `${itemEstado.codigo};`
                        });
                    }

                    this.campanhaEstoqueRequest.id = this.id;
                    this.campanhaEstoqueRequest.estados = estados;

                    const result = await this.$store.dispatch("campanhaEstoqueModule/CreateUpdate", this.campanhaEstoqueRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>